import {createApp} from 'vue'
import App from './peanutbox/PeanutApp'
import ajax from "./lib/ajax.js"
import store from './lib/store'
import VTooltip from 'floating-vue'
import 'floating-vue/dist/style.css'
//VTooltip.enabled = window.innerWidth > 768


window.ajax = ajax
const $app = createApp(App)
//$app.use(VTooltip, {defaultOffset: '5px', defaultPlacement: 'top', defaultDelay: { show: 100, hide: 100 }, defaultBoundariesElement: 'window'})
// https://v-tooltip.netlify.app/migration/migration-from-v2.html#global-configuration
$app.use(VTooltip)//, {distance: 5, skidding: 5, defaultPlacement: 'top', delay: 100, boundary: 'window'})


$app.config.warnHandler = (err, vm, info) => {
    console.warn('warnHandler', err, 'vm:', vm, 'info:', info);
    return false;
};

$app.config.errorHandler = (err, vm, info) => {
    console.error('errorHandler', err, 'vm:', vm, 'info:', info);
    vm.$root.errorScreen(err);
    return false;
};

$app.mount('#app')
