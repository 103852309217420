import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core'

/********************************************/
/*************  SOLID ICONS  ****************/
/********************************************/
import {
    faArchive as fasArchive,
    faBan as fasBan,
    faBars as fasBars,
    faBell as fasBell,
    faCaretDown as fasCaretDown,
    faCaretUp as fasCaretUp,
    faCheck as fasCheck,
    faChevronLeft as fasChevronLeft,
    faChevronRight as fasChevronRight,
    faCloudUploadAlt as fasCloudUploadAlt,
    faCog as fasCog,
    faCopy as fasCopy,
    faDiagramProject as fasDiagramProject,
    faDownload as fasDownload,
    faSortAmountDown as fasSortAmountDown,
    faEllipsisH as fasEllipsisH,
    faExclamation as fasExclamation,
    faExclamationTriangle as fasExclamationTriangle,
    faEnvelope as fasEnvelope,
    faFilter as fasFilter,
    faFloppyDisk as fasFloppyDisk,
    faGlobe as fasGlobe,
    faGrid2 as fasGrid2,
    faInboxOut as fasInboxOut,
    faInfo as fasInfo,
    faLocationPin as fasLocationPin,
    faLock as fasLock,
    faLevelUp as fasLevelUp,
    faMapMarker as fasMapMarker,
    faMinus as fasMinus,
    faMinusOctagon as fasMinusOctagon,
    faPalette as fasPalette,
    faPlusCircle as fasPlusCircle,
    faPrint as fasPrint,
    faQuestionCircle as fasQuestionCircle,
    faRulerCombined as fasRulerCombined,
    faSave as fasSave,
    faShareSquare as fasShareSquare,
    faSitemap as fasSitemap,
    faSortAlphaDown as fasSortAlphaDown,
    faSortAlphaUp as fasSortAlphaUp,
    faSync as fasSync,
    faThLarge as fasThLarge,
    faTimes as fasTimes,
    faTrash as fasTrash,
    faUndo as fasUndo,
    faUnlock as fasUnlock,
    faUpload as fasUpload,
    faUser as fasUser,
    faUserGroup as fasUserGroup
} from '@fortawesome/pro-solid-svg-icons'

library.add(
    fasArchive,
    fasBan,
    fasBars,
    fasBell,
    fasCaretDown,
    fasCaretUp,
    fasCheck,
    fasChevronLeft,
    fasChevronRight,
    fasCloudUploadAlt,
    fasCog,
    fasCopy,
    fasDiagramProject,
    fasDownload,
    fasSortAmountDown,
    fasEllipsisH,
    fasExclamation,
    fasExclamationTriangle,
    fasEnvelope,
    fasFilter,
    fasFloppyDisk,
    fasGlobe,
    fasGrid2,
    fasInboxOut,
    fasInfo,
    fasLocationPin,
    fasLock,
    fasLevelUp,
    fasMapMarker,
    fasMinus,
    fasMinusOctagon,
    fasPalette,
    fasPlusCircle,
    fasPrint,
    fasQuestionCircle,
    fasRulerCombined,
    fasSave,
    fasShareSquare,
    fasSitemap,
    fasSync,
    fasThLarge,
    fasTimes,
    fasTrash,
    fasUndo,
    fasUnlock,
    fasUpload,
    fasUser,
    fasUserGroup
)

/********************************************/
/*************  REGULAR ICONS  **************/
/********************************************/
import {
    faBan as farBan,
    faCalendarAlt as farCalendarAlt,
    faClipboard as farClipboard,
    faEllipsisV as farEllipsisV,
    faLayerGroup as farLayerGroup,
    faInfoSquare as farInfoSquare,
    faPenSquare as farPenSquare,
    faPlus as farPlus,
    faSadCry as farSadCry,
    faSearch as farSearch
} from '@fortawesome/pro-regular-svg-icons'

library.add(
    farBan,
    farCalendarAlt,
    farClipboard,
    farEllipsisV,
    farLayerGroup,
    farInfoSquare,
    farPenSquare,
    farPlus,
    farSadCry,
    farSearch
)

/********************************************/
/*************  LIGHT ICONS  ****************/
/********************************************/
import {
    faAlienMonster as falAlienMonster,
    faAngleRight as falAngleRight,
    faAnglesDown as falAnglesDown,
    faArchive as falArchive,
    faArrowDown as falArrowDown,
    faArrowRight as falArrowRight,
    faArrowRightFromBracket as falArrowRightFromBracket,
    faArrowLeft as falArrowLeft,
    faArrowUp as falArrowUp,
    faArrowsV as falArrowsV,
    faAsterisk as falAsterisk,
    faAt as falAt,
    faBars as falBars,
    faBlock as falBlock,
    faBlockBrick as falBlockBrick,
    faBlockQuestion as falBlockQuestion,
    faBook as falBook,
    faBoxFull as falBoxFull,
    faCalendarAlt as falCalendarAlt,
    faChartLine as falChartLine,
    faCheck as falCheck,
    faCheckDouble as falCheckDouble,
    faChevronDoubleUp as falChevronDoubleUp,
    faChevronDown as falChevronDown,
    faChevronDoubleLeft as falChevronDoubleLeft,
    faChevronDoubleRight as falChevronDoubleRight,
    faChevronLeft as falChevronLeft,
    faChevronRight as falChevronRight,
    faChevronUp as falChevronUp,
    faClipboard as falClipboard,
    faCodeBranch as falCodeBranch,
    faCloud as falCloud,
    faCloudUpload as falCloudUpload,
    faClone as falClone,
    faCog as falCog,
    faCogs as falCogs,
    faCopy as falCopy,
    faCube as falCube,
    faCubes as falCubes,
    faDesktop as falDesktop,
    faDiagramProject as falDiagramProject,
    faDownload as falDownload,
    faEdit as falEdit,
    faEllipsisH as falEllipsisH,
    faEllipsisHAlt as falEllipsisHAlt,
    faEnvelope as falEnvelope,
    faExclamation as falExclamation,
    faExternalLink as falExternalLink,
    faEye as falEye,
    faEyeSlash as falEyeSlash,
    faFile as falFile,
    faFileAlt as falFileAlt,
    faFileContract as falFileContract,
    faFileExport as falFileExport,
    faFileImport as falFileImport,
    faFileZipper as farFileZipper,
    faFilter as falFilter,
    faFingerprint as falFingerprint,
    faFolder as falFolder,
    faFolderOpen as falFolderOpen,
    faFrog as falFrog,
    faGlobe as falGlobe,
    faGrid2 as falGrid2,
    faHandReceiving as falHandReceiving,
    faHome as falHome,
    faHourglassEnd as falHourglassEnd,
    faImage as falImage,
    faInbox as falInbox,
    faInfo as falInfo,
    faInfoCircle as falInfoCircle,
    faKey as falKey,
    faLifeRing as falLifeRing,
    faLink as falLink,
    faLocationPin as falLocationPin,
    faLock as falLock,
    faLongArrowDown as falLongArrowDown,
    faLongArrowUp as falLongArrowUp,
    faMinus as falMinus,
    faMinusOctagon as falMinusOctagon,
    faPalette as falPalette,
    faPaperclip as falPaperclip,
    faPaperPlane as falPaperPlane,
    faPause as falPause,
    faPenNib as falPenNib,
    faPhone as falPhone,
    faPuzzlePiece as falPuzzlePiece,
    faPlay as falPlay,
    faPlus as falPlus,
    faRedo as falRedo,
    faRetweet as falRetweet,
    faSave as falSave,
    faSearch as falSearch,
    faFilePdf as falFilePdf,
    faFileSearch as falFileSearch,
    faServer as falServer,
    faShareAlt as falShareAlt,
    faShareSquare as falShareSquare,
    faShieldCheck as falShieldCheck,
    faSitemap as falSitemap,
    faSortAlphaDown as falSortAlphaDown,
    faSortAlphaUp as falSortAlphaUp,
    faSortAmountDown as falSortAmountDown,
    faSortNumericDown as falSortNumericDown,
    faSortNumericUp as falSortNumericUp,
    faStar as falStar,
    faStreetView as falStreetView,
    faSync as falSync,
    faTabletAndroid as falTabletAndroid,
    faThLarge as falThLarge,
    faTimes as falTimes,
    faTrash as falTrash,
    faTurtle as falTurtle,
    faUndo as falUndo,
    faUnicorn as falUnicorn,
    faUnlock as falUnlock,
    faUpload as falUpload,
    faUser as falUser,
    faUserClock as falUserClock,
    faUserCog as falUserCog,
    faUsers as falUsers,
    faUsersCog as falUsersCog,
    faUserGroup as falUserGroup,
    faUserLock as falUserLock,
    faUserShield as falUserShield,
    faUserUnlock as falUserUnlock,
    faWindowFlip as falWindowFlip,
    faWindowMaximize as falWindowMaximize,
    faWrench as falWrench,
    faXmark as falXmark
} from '@fortawesome/pro-light-svg-icons'

library.add(
    falAlienMonster,
    falAngleRight,
    falAnglesDown,
    falArchive,
    falArrowDown,
    falArrowLeft,
    falArrowRight,
    falArrowRightFromBracket,
    falArrowUp,
    falArrowsV,
    falAsterisk,
    falAt,
    falBars,
    falBlock,
    falBlockBrick,
    falBlockQuestion,
    falBook,
    falBoxFull,
    falCalendarAlt,
    falChartLine,
    falCheck,
    falCheckDouble,
    falChevronDoubleUp,
    falChevronDown,
    falChevronDoubleLeft,
    falChevronDoubleRight,
    falChevronLeft,
    falChevronRight,
    falChevronUp,
    falClipboard,
    falCloud,
    falCloudUpload,
    falClone,
    falCodeBranch,
    falCog,
    falCogs,
    falCopy,
    falCube,
    falCubes,
    falDesktop,
    falDiagramProject,
    falDownload,
    falEdit,
    falEllipsisH,
    falEllipsisHAlt,
    falEnvelope,
    falExclamation,
    falExternalLink,
    falEye,
    falEyeSlash,
    falFile,
    falFileAlt,
    falFileContract,
    falFileExport,
    falFileImport,
    falFilePdf,
    farFileZipper,
    falFilter,
    falFingerprint,
    falFolder,
    falFolderOpen,
    falFrog,
    falGlobe,
    falGrid2,
    falHandReceiving,
    falHome,
    falHourglassEnd,
    falImage,
    falInbox,
    falInfo,
    falInfoCircle,
    falKey,
    falLifeRing,
    falLink,
    falLocationPin,
    falLock,
    falLongArrowDown,
    falLongArrowUp,
    falMinus,
    falMinusOctagon,
    falPalette,
    falPaperclip,
    falPaperPlane,
    falPause,
    falPenNib,
    falPhone,
    falPlay,
    falPlus,
    falPuzzlePiece,
    falRedo,
    falRetweet,
    falSave,
    falSearch,
    falFileSearch,
    falServer,
    falShareAlt,
    falShareSquare,
    falShieldCheck,
    falSitemap,
    falSortAlphaDown,
    falSortAlphaUp,
    falSortAmountDown,
    falSortNumericDown,
    falSortNumericUp,
    falStar,
    falStreetView,
    falSync,
    falTabletAndroid,
    falThLarge,
    falTimes,
    falTrash,
    falTurtle,
    falUndo,
    falUnicorn,
    falUnlock,
    falUpload,
    falUser,
    falUserClock,
    falUserCog,
    falUsers,
    falUsersCog,
    falUserGroup,
    falUserLock,
    falUserShield,
    falUserUnlock,
    falWindowFlip,
    falWindowMaximize,
    falWrench,
    falXmark
)

/********************************************/
/*************  THIN ICONS  **************/
/********************************************/
import {
    faAlignLeft as fatAlignLeft,
    faArrowUpRightAndArrowDownLeftFromCenter as fatArrowUpRightAndArrowDownLeftFromCenter,
    faArrowsLeftRight as fatArrowsLeftRight,
    faBook as fatBook,
    faChevronRight as fatChevronRight,
    faCircleInfo as fatCircleInfo,
    faCodeBranch as fatCodeBranch,
    faCube as fatCube,
    faEnvelope as fatEnvelope,
    faExternalLink as fatExternalLink,
    faFileLines as fatFileLines,
    faFolderOpen as fatFolderOpen,
    faHouse as fatHouse,
    faImage as fatImage,
    faLink as fatLink,
    faPaperclip as fatPaperclip,
    faCog as fatCog,
    faSearch as fatSearch,
    faShareNodes as fatShareNodes,
    faShieldCheck as fatShieldCheck,
    faUpRightFromSquare as fatUpRightFromSquare,
    faUsers as fatUsers,
    faUserGroup as fatUserGroup,
    faWrench as fatWrench

} from '@fortawesome/pro-thin-svg-icons'

library.add(
    fatAlignLeft,
    fatArrowUpRightAndArrowDownLeftFromCenter,
    fatArrowsLeftRight,
    fatBook,
    fatCodeBranch,
    fatChevronRight,
    fatCircleInfo,
    fatCube,
    fatEnvelope,
    fatExternalLink,
    fatFileLines,
    fatFolderOpen,
    fatHouse,
    fatImage,
    fatLink,
    fatPaperclip,
    fatCog,
    fatSearch,
    fatShareNodes,
    fatShieldCheck,
    fatUsers,
    fatUpRightFromSquare,
    fatUserGroup,
    fatWrench
)

/********************************************/
/*************  DUOTONE ICONS  **************/
/********************************************/
import {
    faArchive as fadArchive,
    faCloud as fadCloud,
    faHourglassEnd as fadHourglassEnd,
    faLock as fadLock,
    faShareAlt as fadShareAlt,
    faSpinnerThird as fasSpinnerThird,
    faTrash as fadTrash,
    faUnicorn as fadUnicorn
} from '@fortawesome/pro-duotone-svg-icons'

library.add(
    fadArchive,
    fadCloud,
    fadHourglassEnd,
    fadLock,
    fadShareAlt,
    fasSpinnerThird,
    fadTrash,
    fadUnicorn
)

export default FontAwesomeIcon;